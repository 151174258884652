<div fxLayout="column" fxLayoutAlign="start center" fxFill class="mat-typography background color">
  <particles [style]="myStyle" [width]="100" [height]="100" [params]="myParams"></particles>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-button color="primary" fxHide.xs (click)="triggerScrollTo(1)">Nosotros</button>
    <button mat-button color="primary" fxHide.xs (click)="triggerScrollTo(2)">Proyectos</button>
    <img alt="Logo BWR" fxFlex src="assets/img/logo.svg">
    <button mat-button color="primary" fxHide.xs (click)="triggerScrollTo(3)">Tecnologia</button>
    <button mat-button color="primary" fxHide.xs (click)="triggerScrollTo(4)">Contacto</button>
  </div>
  <div fxLayout="row">
    <h2><strong>Mobile & Web Development</strong></h2>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <h1>
      Creamos la aplicación que estas pensando
    </h1>
  </div>
  <div fxFlex fxFill fxHide fxShow.xs fxLayout="row">
    <div fxFlex fxLayout="column">
      <button fxFlex mat-stroked-button color="primary" (click)="triggerScrollTo(1)">Nosotros</button>
      <button fxFlex mat-stroked-button color="primary" (click)="triggerScrollTo(2)">Proyectos</button>
    </div>
    <div fxFlex fxLayout="column">
      <button fxFlex mat-stroked-button color="primary" (click)="triggerScrollTo(3)">Tecnologia</button>
      <button fxFlex mat-stroked-button color="primary" (click)="triggerScrollTo(4)">Contacto</button>
    </div>
  </div>
</div>
