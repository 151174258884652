<div fxLayout="column" fxLayoutAlign fxFill class="mat-typography background" >
  <div fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
    <h2><strong>Tecnología</strong></h2>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="start center">
    <div fxFlex="50" fxFill fxLayout="column">
      <div fxFlex="10">
        <h4>Apps Moviles y Webs construidas con...</h4>
      </div>
      <div fxFlex fxFill fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="space-evenly center">
        <div fxFlex="25" fxFill>
          <mat-card fxFill fxLayout.xs="row" fxLayout="column">
            <div fxFlex="nogrow" fxFlexAlign.gt-sm="center"><img class="logo" src="/assets/img/flutter.png" alt="Flutter Logo"></div>
            <div fxFlex fxLayoutAlign="center center" ><h2>Flutter</h2></div>
          </mat-card>
        </div>
        <div fxFlex="25" fxFill>
          <mat-card fxFill fxLayout.xs="row" fxLayout="column">
            <div fxFlex="nogrow" fxFlexAlign.gt-sm="center"><img class="logo" alt="Android Logo" src="/assets/img/android.png"></div>
            <div fxFlex fxLayoutAlign="center center" ><h2>Android</h2></div>
          </mat-card>
        </div>
        <div fxFlex="25" fxFill>
          <mat-card fxFill fxLayout.xs="row" fxLayout="column">
            <div fxFlex="nogrow" fxFlexAlign.gt-sm="center"><img class="logo" alt="Angular Logo" src="/assets/img/angular.png"></div>
            <div fxFlex fxLayoutAlign="center center" ><h2>Angular</h2></div>
          </mat-card>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxFill fxLayout="column">
      <div fxFlex="10">
        <h4>Plataformas construidas con...</h4>
      </div>
      <div fxFlex fxFill fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="space-evenly center">
        <div fxFlex="25" fxFill>
          <mat-card fxFill fxLayout.xs="row" fxLayout="column">
            <div fxFlex="nogrow" fxFlexAlign.gt-sm="center"><img class="logo" alt="Firebase Logo" src="/assets/img/firebase.png"></div>
            <div fxFlex fxLayoutAlign="center center"><h2>Firebase</h2></div>
          </mat-card>
        </div>
        <div fxFlex="25" fxFill>
          <mat-card fxFill fxLayout.xs="row" fxLayout="column">
            <div fxFlex="nogrow" fxFlexAlign.gt-sm="center">
              <img class="logo" alt="NodeJS Logo" src="/assets/img/node.png"></div>
            <div fxFlex fxLayoutAlign="center center"><h2>Node.js®</h2></div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
