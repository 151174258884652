<div fxLayout="column" fxLayoutGap="1rem" class="section mat-typography">
  <h1 fxFlexAlign="center" class="title">Proyectos</h1>
  <div fxLayout="row" fxLayoutAlign="space-evenly">
    <button mat-stroked-button (click)="changeProjects(1)" *ngIf="selected != 1">Todos</button>
    <button mat-flat-button color="primary" *ngIf="selected == 1">Todos</button>
    <button mat-stroked-button (click)="changeProjects(2)" *ngIf="selected != 2">App Web</button>
    <button mat-flat-button color="primary" *ngIf="selected == 2">App Web</button>
    <button mat-stroked-button (click)="changeProjects(3)" *ngIf="selected != 3">App Móvil</button>
    <button mat-flat-button color="primary" *ngIf="selected == 3">App Móvil</button>
  </div>
  <mat-grid-list fxFlex [cols]="breakpoint"
                 rowHeight="fit"
                 gutterSize="1rem"
                 (window:resize)="onResize($event)"
                 class="sample-container">
    <mat-grid-tile *ngIf="selected == 1 || selected == 3" class="project comicomi" (click)="showContent(1)">
      <mat-grid-tile-footer style="background: rgba(0,0,0,.75)">
        Repartición de alimentos
      </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="selected == 1 || selected == 3" class="project crypto" (click)="showContent(2)">
      <mat-grid-tile-footer style="background: rgba(0,0,0,.75)">
        Intercambio de criptomonedas
      </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="selected == 1 || selected == 2" class="project tcph" (click)="showContent(3)">
      <mat-grid-tile-footer style="background: rgba(0,0,0,.75)">
        Gestor de donaciones
      </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="selected == 1 || selected == 3" class="project maps" (click)="showContent(4)">
      <mat-grid-tile-footer style="background: rgba(0,0,0,.75)">
        Mapas
      </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="selected == 1 || selected == 3" class="project chat" (click)="showContent(5)">
      <mat-grid-tile-footer style="background: rgba(0,0,0,.75)">
        Chat
      </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="selected == 1 || selected == 2" class="project oink" (click)="showContent(0)">
      <mat-grid-tile-footer style="background: rgba(0,0,0,.75)">
        Administrador de música
      </mat-grid-tile-footer>
    </mat-grid-tile>
  </mat-grid-list>
</div>
