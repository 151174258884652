<div fxLayout="column" class="mat-typography background color">
  <div fxFlex="10" fxLayoutAlign="center center">
    <h2><strong>Contacto</strong></h2>
  </div>
  <div fxFlex="10" fxLayoutAlign="center center">
    <h4>
      Queremos conocerte y escuchar tus ideas, para volverlas realidad. Ponte en contacto con nosotros mediante alguno nuestros canales de comunicación.
    </h4>
  </div>
  <div fxFlex="60" fxLayout="row" fxLayout.xs="column" >
    <div fxFlex="50" fxLayout="row" fxFlex.xs="100" class="content">
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" class="button-group">
        <div fxFlex>
          <button mat-raised-button color="primary" (click)="open('mailto:info@bwr.mx')">
            Escribenos
            <mat-icon aria-label="MAil" fontSet="fas" fontIcon="fa-envelope"></mat-icon>
          </button>
        </div>
        <div fxFlex>
          <button mat-raised-button color="primary" (click)="open('tel:+525554487791')">
            Hablanos
            <mat-icon aria-label="Telefono" fontSet="fas" fontIcon="fa-phone"></mat-icon>
          </button>
        </div>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex>
          <button mat-stroked-button color="secondary" (click)="open('https://www.linkedin.com/company/bwr-mx/')">
            <mat-icon aria-label="BWR Linkedin" fontSet="fab" fontIcon="fa-linkedin-in"></mat-icon>
            Linkedin
          </button>
        </div>
        <div fxFlex>
          <button mat-stroked-button color="secondary" (click)="open('https://www.facebook.com/BWRmex/')">
            <mat-icon aria-label="BWR Facebook" fontSet="fab" fontIcon="fa-facebook-f"></mat-icon>
            Facebook
          </button>
        </div>
        <div fxFlex>
          <button mat-stroked-button color="secondary" (click)="open('https://twitter.com/BWR_MX')">
            <mat-icon aria-label="BWR Twitter" fontSet="fab" fontIcon="fa-twitter"></mat-icon>
            Twitter
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" class="picture-all" ngClass.xs="picture-small" ngClass.sm="picture-small" fxHide.xs>
      <picture>
        <source srcset='assets/img/contacto.jp2' type='image/jp2'>
        <source srcset='assets/img/contacto.webp' type='image/webp'>
        <img srcset='assets/img/contacto.png' alt='Imagen de contacto'>
      </picture>
    </div>
  </div>
  <div fxFlex="20" fxLayout="column" fxLayoutAlign="end center">
    <h6>
      <small>©2018 Google LLC All rights reserved. Firebase and the Firebase logo are registered trademarks of Google LLC.</small>
    </h6>
    <h6>
      <small>The Android robot is reproduced or modified from work created and shared by Google and used according to terms described in the Creative Commons 3.0 Attribution License</small>
    </h6>
    <h6>
      México 2018 BWR  consulta nuestro <a href="http://www.bwr.mx/aviso-de-privacidad">Aviso de Privacidad</a>
    </h6>
  </div>
</div>
