import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {

  url = ['oink', 'comicomi', 'crypto', 'tcph', 'maps', 'chat'];
  pos = 1;

  constructor(
    public dialogRef: MatDialogRef<ImageSliderComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
  }

  next() {
    this.pos === 5 ? this.pos = 1 : this.pos++;
  }

  prev() {
    this.pos === 1 ? this.pos = 5 : this.pos--;
  }

}
