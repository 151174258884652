<div fxLayout="column">
  <div fxFlex="50" fxLayoutAlign="center center">
    <img alt="Imagen de aplicacion" src="assets/img/{{url[data]}}/{{pos}}.jpg">
  </div>
  <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex fxLayoutAlign="center">
      <button mat-icon-button (click)="prev()" color="primary">
        <mat-icon aria-label="Anterior" fontSet="fas" fontIcon="fa-chevron-circle-left"></mat-icon>
      </button>
    </div>
    <div fxFlex fxLayoutAlign="center">
      <button mat-icon-button (click)="next()" color="primary">
        <mat-icon aria-label="Siguiente" fontSet="fas" fontIcon="fa-chevron-circle-right"></mat-icon>
      </button>
    </div>

  </div>
</div>
