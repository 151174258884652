import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ImageSliderComponent } from './image-slider/image-slider.component';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {

  constructor(private dialog: MatDialog) {}

  breakpoint = 3;
  selected = 1;

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 600) ? 2 : 3;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 600) ? 2 : 3;
  }

  showContent(id: number) {
    const dialogRef = this.dialog.open(ImageSliderComponent, {
      height: 'fit-content',
      data: id
    });
  }

  changeProjects(id: number) {
    this.selected = id;
  }
}
