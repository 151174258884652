import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// Components

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ContactComponent } from './contact/contact.component';
import { TechComponent } from './tech/tech.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { ImageSliderComponent } from './proyectos/image-slider/image-slider.component';

// Angular Material

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatGridListModule } from '@angular/material';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ParticlesModule } from '@bbougot/angular-particle';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContactComponent,
    TechComponent,
    NosotrosComponent,
    ProyectosComponent,
    ImageSliderComponent
  ],
  imports: [
    ScrollToModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatIconModule,
    ParticlesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  entryComponents: [ImageSliderComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
