<div fxLayout="column" fxLayoutAlign="start" class="mat-typography background">
  <div fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
    <h2><strong>Nosotros</strong></h2>
  </div>
  <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
    <div fxFlex="50" fxHide.xs fxLayoutAlign="center center">
      <object alt="Nube de palabras" id="cloud" data="/assets/img/wordcloud.svg" type="image/svg+xml"></object>
    </div>
    <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">
      <p>
        A través de nuestros servicios de desarrollo de aplicaciones móviles creamos o extendemos apps, ya sean nativas o híbridas.
        Habilitamos la capacidad de las plataformas de software y las ponemos literalmente al alcance de su mano.
      </p>
      <ul>
        <li>Análisis de requerimientos</li>
        <li>Diseño de aplicaciones iOS, Android, con frameworks o aceleradores</li>
        <li>Liberación de aplicaciones con y sin el marketplace de plataformas</li>
        <li>Uso de sensores de proximidad, georeferenciales, cámara, biométricos, acelerómetro, giroscopio,
          barómetro, de luz, entre otros</li>
        <li>Uso de plataformas y herramientas para las pruebas controladas en la liberación de aplicaciones móviles</li>
        <li>Gestión de repositorios y metodologías para la creación de aplicaciones móviles</li>
        <li>Construcción de aplicaciones empresariales, personales, juegos de realidad aumentada, 360°, inmersivas, o realidad virtual</li>
      </ul>
    </div>
  </div>
</div>
