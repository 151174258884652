/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "@nicky-lenaers/ngx-scroll-to";
import * as i5 from "./nosotros/nosotros.component.ngfactory";
import * as i6 from "./nosotros/nosotros.component";
import * as i7 from "./proyectos/proyectos.component.ngfactory";
import * as i8 from "./proyectos/proyectos.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./tech/tech.component.ngfactory";
import * as i11 from "./tech/tech.component";
import * as i12 from "./contact/contact.component.ngfactory";
import * as i13 from "./contact/contact.component";
import * as i14 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [["id", "headerSection"]], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.ScrollToService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-nosotros", [["id", "nosotrosSection"]], null, null, null, i5.View_NosotrosComponent_0, i5.RenderType_NosotrosComponent)), i1.ɵdid(3, 114688, null, 0, i6.NosotrosComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-proyectos", [["id", "proyectosSection"]], null, null, null, i7.View_ProyectosComponent_0, i7.RenderType_ProyectosComponent)), i1.ɵdid(5, 114688, null, 0, i8.ProyectosComponent, [i9.MatDialog], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-tech", [["id", "techSection"]], null, null, null, i10.View_TechComponent_0, i10.RenderType_TechComponent)), i1.ɵdid(7, 114688, null, 0, i11.TechComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-contact", [["id", "contactSection"]], null, null, null, i12.View_ContactComponent_0, i12.RenderType_ContactComponent)), i1.ɵdid(9, 114688, null, 0, i13.ContactComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i14.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
